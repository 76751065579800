import { useSelector } from "react-redux";
import SpinnerLoader from "../../components/SpinnerLoader";
import { RootState } from "../../redux/store";

function HomePage() {
  const { message, loading } = useSelector((state: RootState) => state.auth);

  // const dispatch = useAppDispatch();

  // const navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(getAuth());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (isAuthorized) navigate("/courses");
  // }, [isAuthorized]);

  return (
    <div className="flex items-center w-full justify-center">
      {!loading && <p>{message}</p>}
      {loading && <SpinnerLoader />}
    </div>
  );
}

export default HomePage;
