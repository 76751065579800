import { useEffect, useRef, useState } from "react";
import { GoSearch } from "react-icons/go";
import { IoFunnelOutline } from "react-icons/io5";

interface SearchWithFilterProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  disabled?: boolean;
}

function SearchWithFilter({
  searchTerm,
  onSearchChange,
  disabled,
}: SearchWithFilterProps) {
  // const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [inputValue, setInputValue] = useState(searchTerm); // Local state for debouncing
  const filterMenuRef: any = useRef(null);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null); // Timeout reference for debounce

  // const toggleFilterMenu = () => {
  //   setIsFilterOpen((prev) => !prev);
  // };

  // const handleClickOutside = (event: any) => {
  //   if (
  //     filterMenuRef.current &&
  //     !filterMenuRef.current.contains(event.target)
  //   ) {
  //     setIsFilterOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  // Debounced Search Handler
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    // Clear previous timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set new timeout
    debounceTimeout.current = setTimeout(() => {
      onSearchChange(value); // Trigger the actual search after debounce delay
    }, 1500); // 5 seconds debounce
  };

  return (
    <div
      className="px-5 py-2 bg-white flex items-center justify-start w-full gap-8 shadow-md rounded-lg"
      ref={filterMenuRef}
    >
      {/* <div className="relative">
        <div
          className="flex items-center gap-2 text-base cursor-pointer"
          onClick={toggleFilterMenu}
        >
          <IoFunnelOutline size={18} />
          Filter
        </div>

        {isFilterOpen && (
          <div className="absolute top-10 left-0 bg-white shadow-md border p-4 rounded-md w-64">
            <h3 className="text-lg font-semibold mb-3">Filters</h3>
            <div className="flex flex-col gap-3">
              <div className="w-full border p-2 rounded-md cursor-pointer hover:border-[#752938] transition-all duration-300">
                Category 1
              </div>
              <div className="w-full border p-2 rounded-md cursor-pointer hover:border-[#752938] transition-all duration-300">
                Category 2
              </div>
              <button
                className="bg-[#752938] text-white px-4 py-2 rounded-md hover:bg-[#5a1f2a]"
                onClick={() => setIsFilterOpen(false)}
              >
                Apply Filters
              </button>
            </div>
          </div>
        )}
      </div> */}
      <div className="flex items-center gap-2 w-full">
        <GoSearch size={18} />
        <input
          type="text"
          placeholder="Search"
          className="w-full p-2 border-b-2 border-transparent focus:border-[#752938] focus:outline-none"
          value={inputValue} // Use local state for immediate updates
          onChange={handleInputChange} // Debounced change handler
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default SearchWithFilter;
