import React from "react";

function Header() {
  const username = sessionStorage.getItem("username");

  return (
    <div className="flex flex-col justify-start items-start w-full">
      <p className="text-2xl font-semibold text-[#ac112d]">Welcome</p>
      <p className="text-lg font-light text-[#ac112d]">
        {username || `Dean JEANE`}
      </p>
    </div>
  );
}

export default Header;
