import { MdOutlineEventSeat } from "react-icons/md";

function TabNavbar({
  id,
  handleSaveAndPublish,
  isDisable,
  isLoading,
}: {
  id: string;
  handleSaveAndPublish?: () => void;
  isDisable?: boolean;
  isLoading?: boolean;
}) {
  const x = [
    // {
    //   id: 1,
    //   icon: <FaUserSlash color="#752938" size={20} />,
    //   title: "SUSPENSIONS",
    //   url: "#",
    // },
    // {
    //   id: 2,
    //   icon: <BiSolidCalculator color="#752938" size={20} />,
    //   title: "REPORTS",
    //   url: "#",
    // },
    {
      id: 3,
      icon: <MdOutlineEventSeat color="#752938" size={20} />,
      title: "SEAT ALLOCATION",
      url: "#",
    },
    // {
    //   id: 4,
    //   icon: <PiCoinsThin color="#752938" size={20} />,
    //   title: "TRAINING COST",
    //   url: "#",
    // },
  ];

  return (
    <div className="flex items-center justify-between gap-5 w-full">
      <div className="flex items-center justify-start gap-5 w-full">
        {x.map((item) => {
          return (
            <div className="flex items-center justify-start gap-2 cursor-pointer group underline underline-offset-2 duration-200 transition-all decoration-[#752938]">
              <div>{item.icon}</div>
              <p className="text-sm text-[#752938]">{item.title}</p>
            </div>
          );
        })}
      </div>
      {id === "seat-allocation" && (
        <div className="flex items-center gap-5 w-full justify-end">
          <button
            disabled={isDisable || isLoading}
            onClick={() => handleSaveAndPublish && handleSaveAndPublish()}
            className={`py-2 px-4 font-semibold bg-[#961e38] text-white ${
              isDisable ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            {isLoading ? "SAVING..." : "SAVE & PUBLISH TRAINING"}
          </button>
        </div>
      )}
    </div>
  );
}

export default TabNavbar;
