import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Courses from "./pages/Courses";
import HomePage from "./pages/Home";
import SeatAllocation from "./pages/SeatAllocations";
import Sessions from "./pages/Sessions";
import ProtectedLayout from "./layout/ProtectedLayout";

function App() {
  return (
    <div className="w-4/5 m-auto px-40 py-16">
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<ProtectedLayout />}>
            <Route path={"/"} element={<HomePage />} />
            <Route path={"/courses"} element={<Courses />} />
            <Route path={"/courses/sessions/:id"} element={<Sessions />} />
            <Route
              path={"/courses/sessions/:id/seat-allocation/:id"}
              element={<SeatAllocation />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
