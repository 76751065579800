import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CourseSessionItem } from "../../interfaces/sessions";
import {
  SessionBranchItem,
  SessionGroupItem,
} from "../../interfaces/allocation";

const initialState = {
  branchLoading: false,
  groupLoading: false,
  groups: [] as SessionGroupItem[],
  branches: [] as SessionBranchItem[],
  selectedSessionData: {} as CourseSessionItem,
  saveLoading: false,
};

export const getCourseSessionBranches = createAsyncThunk(
  "allocation/getCourseSessionBranches",
  async (props: { session_id: number | string }) => {
    let query = `?session_id=${props.session_id}`;

    const url = `${process.env.REACT_APP_BASE_API_URL}/branches${query}`;

    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(`HTTP error! status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Check if the response has a body
    const data = await response.json();

    return data;
  }
);

export const getCourseSessionGroups = createAsyncThunk(
  "allocation/getCourseSessionGroups",
  async (props: { session_id: number | string }) => {
    let query = `?session_id=${props.session_id}`;

    const url = `${process.env.REACT_APP_BASE_API_URL}/groups${query}`;

    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(`HTTP error! status: ${response.status}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Check if the response has a body
    const data = await response.json();
    return data;
  }
);

export const updateSessionSeatAllocation = createAsyncThunk(
  "allocation/updateSessionSeatAllocation",
  async (props: { session_id: number | string; payload: any }) => {
    let query = `?session_id=${props.session_id}`;

    const url = `${process.env.REACT_APP_BASE_API_URL}/seat-allocation${query}`;

    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(props.payload),
    }).then((res) => res.json());

    console.log("updateSessionSeatAllocation res: ", response);
    return response;
  }
);

const allocationSlice = createSlice({
  name: "allocation",
  initialState,
  reducers: {
    setSelectedCourseSessionData: (state, action) => {
      state.selectedSessionData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseSessionBranches.pending, (state) => {
        state.branchLoading = true;
        console.log("getCourseSessionBranches loading");
      })
      .addCase(getCourseSessionBranches.fulfilled, (state, action) => {
        state.branchLoading = false;
        state.branches = action.payload?.data || [];
      })
      .addCase(getCourseSessionBranches.rejected, (state, action) => {
        state.branchLoading = false;
        console.log("getCourseSessionBranches rejected");
      })
      .addCase(getCourseSessionGroups.pending, (state) => {
        state.groupLoading = true;
        console.log("getCourseSessionGroups loading");
      })
      .addCase(getCourseSessionGroups.fulfilled, (state, action) => {
        state.groupLoading = false;
        state.groups = action.payload?.data || [];
      })
      .addCase(getCourseSessionGroups.rejected, (state, action) => {
        state.groupLoading = false;
        console.log("getCourseSessionGroups rejected");
      })
      .addCase(updateSessionSeatAllocation.pending, (state) => {
        state.saveLoading = true;
      })
      .addCase(updateSessionSeatAllocation.fulfilled, (state, action) => {
        state.selectedSessionData = {
          ...state.selectedSessionData,
          ...action.payload?.data,
        };
        state.saveLoading = false;
      })
      .addCase(updateSessionSeatAllocation.rejected, (state, action) => {
        state.saveLoading = false;
      });
  },
});

export default allocationSlice.reducer;

export const { setSelectedCourseSessionData } = allocationSlice.actions;
