import { useEffect, useState } from "react";
import { IoChevronBackSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCourseSessions } from "../../redux/slices/session.slice";
import { RootState, useAppDispatch } from "../../redux/store";
import SearchWithFilter from "../../components/SearchWithFilter";
import SpinnerLoader from "../../components/SpinnerLoader";
import { setSelectedCourseSessionData } from "../../redux/slices/allocation.slice";

function Sessions() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [itemsPerPage, setItemsPerPage] = useState(4);

  const { sessionsData, courseSessionResp, selectedCourseData, loading } =
    useSelector((state: RootState) => state.sessions);

  const { pathname } = useLocation();

  // Handle search input
  const handleSearchChange = (value: string) => {
    setCurrentPage(1);
    setSearchTerm(value);
  };

  // Handle page change
  const handlePageChange = (page: number) => setCurrentPage(page);

  const handleSeatAllocationNavigation = (id: number) => {
    const courseSessionById = sessionsData.find(
      (session) => session.session_id === id
    );

    if (!courseSessionById) return;

    dispatch(setSelectedCourseSessionData(courseSessionById));

    navigate(`${pathname}/seat-allocation/${id}`);
  };

  useEffect(() => {
    const id = pathname.split("/")[3];

    if (id) {
      dispatch(
        getCourseSessions({
          course_id: parseInt(id),
          page: currentPage,
          page_size: itemsPerPage,
          filter_value: searchTerm, // Pass search term to API
        })
      );
    }
  }, [pathname, currentPage, searchTerm, dispatch, itemsPerPage]);

  // Rendered data (from API)
  const totalPages = Math.ceil(
    (courseSessionResp?.total_item_count || 0) / itemsPerPage
  );

  return (
    <div className="flex flex-col items-start justify-start gap-10">
      <div
        onClick={() => navigate(-1)}
        className="flex flex-row items-center justify-start gap-2 cursor-pointer hover:text-[#961e38] duration-300 transition-all"
      >
        <IoChevronBackSharp color="#961e38" />
        <p>Back</p>
      </div>
      {Object.keys(selectedCourseData).length > 0 && (
        <div className="flex flex-row w-full justify-between items-center group cursor-pointer">
          <div className="flex flex-col w-full">
            <p className="text-2xl font-semibold text-[#961e38]">
              {selectedCourseData.course_name}
            </p>
            <div className="flex items-center justify-start gap-5 pt-2">
              <p className="text-xs font-semibold flex items-center justify-start gap-2">
                <span className="text-[#b5b5b5]">Type:</span>
                <span>{selectedCourseData.course_type}</span>
              </p>
              <p className="text-xs font-semibold flex items-center justify-start gap-2">
                <span className="text-[#b5b5b5]">Lang:</span>
                <span>{selectedCourseData.language}</span>
              </p>
              <p className="text-xs font-semibold flex items-center justify-start gap-2">
                <span className="text-[#b5b5b5]">Creation Date:</span>
                <span>{selectedCourseData.creation_date}</span>
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center justify-between w-full gap-5">
        <div className="w-9/12">
          <SearchWithFilter
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange} // Pass setSearchTerm directly as the handler
            disabled={loading}
          />
        </div>
      </div>

      <div className="w-full h-full">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/3 text-center">
                Session Dates
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/3 text-center">
                Session Name
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/3 text-center">
                Seat Allocation
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={3} className="text-center">
                  <div className="flex items-center w-full justify-center">
                    <SpinnerLoader />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {sessionsData.length > 0 ? (
                  <>
                    {sessionsData.map((item, i) => (
                      <tr key={i}>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/3">
                          {item.date_start}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/3">
                          {item.session_name}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/3">
                          {item.session_status !== "EXCLUDED_AT_CREATION" && (
                            <div
                              className="py-2 px-4 cursor-pointer font-semibold bg-[#961e38] text-white text-center"
                              onClick={() => {
                                handleSeatAllocationNavigation(item.session_id);
                              }}
                            >
                              OPEN SEAT ALLOCATION
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={3} className="text-center">
                      No Session Data found for {selectedCourseData.course_name}
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>

      {sessionsData.length > 0 && !loading && (
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-center items-center gap-4 mt-4">
            {/* Previous Button */}
            <button
              className={`px-4 py-2 border rounded ${
                currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>

            {/* Dynamic Page Buttons */}
            {Array.from({ length: Math.min(totalPages, 4) }).map((_, index) => {
              const startPage = Math.max(
                1,
                Math.min(currentPage - 1, totalPages - 3)
              );
              const pageNumber = startPage + index;

              return (
                <button
                  key={pageNumber}
                  className={`px-4 py-2 border rounded ${
                    currentPage === pageNumber
                      ? "bg-[#961e38] text-white"
                      : "bg-white text-[#961e38]"
                  }`}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              );
            })}

            {/* Next Button */}
            <button
              className={`px-4 py-2 border rounded ${
                currentPage === totalPages
                  ? "cursor-not-allowed opacity-50"
                  : ""
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
          <div className="flex justify-center items-center">
            <p className="text-sm font-semibold">
              Showing {itemsPerPage * (currentPage - 1) + 1} to{" "}
              {Math.min(
                itemsPerPage * currentPage,
                courseSessionResp?.total_item_count || 0
              )}{" "}
              of {courseSessionResp?.total_item_count || 0} results
            </p>
            <div className="flex items-center gap-3">
              <p className="text-sm font-semibold">Items per page:</p>
              <select
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
              >
                <option value={4}>4</option>
                <option value={8}>8</option>
                <option value={12}>12</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sessions;
