import React from "react";

function SaveConfirmModal({
  closeModal,
  handleSave,
  loading,
}: {
  closeModal: () => void;
  handleSave: () => void;
  loading?: boolean;
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-5 rounded-md shadow-lg flex flex-col justify-between items-end w-[500px] h-[200px]">
        <div className="flex flex-col items-start justify-start gap-5 w-full">
          <h2 className="text-lg font-semibold text-red-600">Confirm Action</h2>
          <p className="mt-2">
            Are you sure you want to save current seat allocation for this
            session?
          </p>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={handleSave}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default SaveConfirmModal;
