import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import auth from "./slices/auth.slice";
import course from "./slices/couses.slice";
import sessions from "./slices/session.slice";
import allocation from "./slices/allocation.slice";

const store = configureStore({
  reducer: { auth, course, sessions, allocation },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export type RootState = ReturnType<typeof store.getState>;

export default store;
